*,
*::after,
*::before {
	padding: 0;
	margin: 0;
	box-sizing: inherit;
}
html {
	font-size: 62.5%;
	overflow-x: hidden;
}

body {
	box-sizing: border-box;
	overflow: hidden;
	line-height: 1;
	background: hsl(var(--layout-primary-darkest));
}

ul {
	list-style: none;
}

textarea {
	resize: none;
}

a:link,
a:visited {
	text-decoration: none;
	color: inherit;
}

// EXTRA

*:focus {
	outline: 3px solid hsl(var(--color-primary));
}

:focus:not(:focus-visible) {
	outline: none;
}
:focus-visible {
	outline: 3px solid hsl(var(--color-primary));
}

::selection {
	background-color: hsl(var(--color-primary));
	color: hsl(var(--layout-primary));
}

// ::-webkit-scrollbar {
// }

// ::-webkit-scrollbar-thumb {
// }

// .btn {
// 	background: none;
// 	border: none;
// }
